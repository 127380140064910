import Head from 'next/head'
import Navbar from '@/components/common/HeaderNav/LoggedOutNav'
import Alert from '@/components/common/Alert'
import { useCookies } from 'react-cookie'
import { useMemo } from 'react'

export default function LoggedOutLayout({ title = 'NextMe', children }) {
  const [cookies] = useCookies(['nextme_mobile_location'])

  const locationIdCookie = useMemo(() => {
    const locationIdCookieVal = cookies?.['nextme_mobile_location']
    if (locationIdCookieVal) {
      return locationIdCookieVal
    }
  }, [cookies])

  return (
    <>
      <Head>
        <title>{title ? `${title} |` : ''} NextMe</title>
      </Head>
      <Alert />
      {!locationIdCookie && (
        <>
          <Navbar />
          {children}
        </>
      )}
    </>
  )
}
