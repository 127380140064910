import { useCallback } from "react";
import { usePostHog } from "posthog-js/react";
import { UserRoleName } from "@/consts/UserRolesEnum";
import { Account } from "@/api/interface/account";
import { Business } from "@/api/interface/business";
import dayjs from "dayjs";
import useIndustries from "./api-hooks/useIndustries";

/**
 * Expose analytics tracking methods
 * Add properties for A/B testing, feature flags or other functionality as needed.
 * Currently only implements Posthog tracking.
 */


// there are also unverified and incomplete statuses in FE code that don't exist on the BE
export type AccountStatus = 'disabled' | 'enabled' | 'invited'
export type BusinessStatus = 'disabled' | 'enabled' | 'pastDue' | 'frozen'
export type SubscriptionPlanType = 'free' | 'trial' | 'starter' | 'business' | 'custom'
export type SubscriptionStatus = 'active' | 'past_due' | 'unpaid' | 'canceled' | 'incomplete' | 'incomplete_expired' | 'trialing' | 'paused'
// Forward looking - grouping by location is likely in the future
export type GroupType = 'business' | 'location'

export interface IdentifyProperties {
  // posthog sets id, so not needed here
  businessId?: string
  name?: string
  firstName?: string
  lastName?: string
  email?: string
  status?: AccountStatus
  createdAt?: string
  roles?: UserRoleName[]
  phone?: string
  groups?: {
    type?: GroupType
    key?: string // object id
  }
  // !!! This is only necessary because the free PostHog plan doesn't include groups
  // When moving to a paid plan, this would be part of the group call instead.
  business?: {
    id: string
    industryId?: string
    industry?: string
    name?: string
    phone?: string
    status?: BusinessStatus
    locationCount?: number
    planName?: string
    trialEndsAt?: string
    createdAt?: string
  }
}

export interface GroupProperties {
  type: GroupType
  id: string // posthog calls this group_key, only biz id to start
  name?: string
  phone?: string
  status?: BusinessStatus
  industry?: string
  locationCount?: number // defaults to 1
  planName?: SubscriptionPlanType
  trialEndsAt?: string // data as string
}

// export type WindowWithIntercom =

export default function useAnalytics() {
  const posthog = usePostHog()
  const { industries } = useIndustries()

  const updateIntercom = useCallback(async (accountId: string, properties?: any) => {
    const businessIndustry = industries?.find((item) => item.id === properties?.business?.industryId)

    const intercomProps = {
      ...properties,
      app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
      external_id: accountId,
      user_id: accountId,
      name: `${properties?.firstName} ${properties?.lastName}`,
      role: properties?.roles?.[0],
      phone: properties?.phone ? `${properties?.phone}` : null,
      created_at: properties?.createdAt ? dayjs(properties?.createdAt).unix() : null,
      company: {
        company_id: properties?.businessId,
        name: properties?.business?.name,
        phone: properties?.business?.phone,
        plan: properties?.business?.planName,
        created_at: properties?.business?.createdAt ? dayjs(properties?.business?.createdAt).unix() : null,
        COMPANY_INDUSTRY: businessIndustry?.name,
        COMPANY_LOCATIONS: properties?.business?.locationCount,
        COMPANY_STATUS: properties?.business?.status,
        BUSINESS_PHONE: properties?.business?.phone,
        TRIAL_END: properties?.business?.trialEndsAt ? dayjs(properties?.business?.trialEndsAt).unix() : null,
        custom_attributes: {
          COMPANY_INDUSTRY: businessIndustry?.name,
          COMPANY_LOCATIONS: properties?.business?.locationCount,
          COMPANY_STATUS: properties?.business?.status,
          BUSINESS_PHONE: properties?.business?.phone,
          TRIAL_END: properties?.business?.trialEndsAt ? dayjs(properties?.business?.trialEndsAt).unix() : null,
        }
      },
    }
    if (window && window.hasOwnProperty('Intercom')) {
      window.Intercom('update', intercomProps)
    }
    
  }, [industries])

  const identify = useCallback(({ 
    accountId, 
    properties 
  }: {
    accountId: string
    properties?: IdentifyProperties
  }) => {
    
    posthog.identify(accountId, properties)
    updateIntercom(accountId, properties)
  }, [posthog])

  const track = useCallback(({ 
    event, 
    properties 
  } : {
    event: string
    properties?: any
  }) => {
    posthog.capture(event, properties)
  }, [posthog])

  /**
   * Group calls will not be used while on free Posthog plan
   */

  // group identify - used to set initial properties for a company
  const groupIdentify = useCallback(({ groupType, properties }) => {
    // execute posthog.groupIdentify()
  }, [posthog])

  // group - set the group for the current user business. call after identify
  const group = useCallback(({ groupType, groupId }) => {
    // execute posthog.group(groupType, groupId)
  }, [posthog])

  const reset = useCallback(() => {
    posthog.reset()
    if (window && window.hasOwnProperty('Intercom')) {
      window.Intercom('shutdown')
    }
  }, [posthog])

  const buildIdentifyProperties = (account: Account, business: Business): IdentifyProperties => {
    if (business && account) {
      return {
        businessId: business?.id, // set once
        firstName: account?.firstName,
        lastName: account?.lastName,
        email: account?.email,
        createdAt: account?.createdAt, // set once
        roles: account?.roles,
        status: account?.status as AccountStatus,
        business: {
          id: business?.id, // set once
          name: business?.name,
          industryId: business?.industryId,
          phone: business?.phone,
          locationCount: business?.currentSubscriptionPeriod?.locationCount,
          planName: business?.currentSubscriptionPeriod?.subscriptionPlanName,
          status: business?.status as BusinessStatus,
          trialEndsAt: business?.trialEndsAt,
          createdAt: business?.createdAt,
        },
      }
    }
    return {}
  }

  return {
    analytics: {
      identify,
      group,
      groupIdentify,
      track,
      reset,
      utils: {
        buildIdentifyProperties,
      },
    },
  } 
}