import { parseISO, sub } from 'date-fns'

export const isValidEmail = (email: string) =>
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email
  )

export const isValidPhone = (phone: string) =>
  /^(\d{3})\D*(\d{3})\D*(\d{4})/.test(phone)

export const isValidPostalCode = (postalCode: string, country: "CA" | "US") => {
  switch (country) {
    case "CA":
      return /\w\d\w\s?\d\w\d/.test(postalCode)
    case "US":
      return /\d{5}(-\d{4})?/.test(postalCode)
  }
}

//* because even though you could just ask for val.length,
//* it's better to be explicit with our types because yikes javascript
export const isValidValue = (val: string | number | Array<any>) => {
  const isValid = Array.isArray(val) ? val.length > 0 : val && JSON.stringify(val).length > 0
  return isValid
}
  

export const isValidDateFieldValue = (val?: string) => {
  return val && /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(val)
}
export const validateDateFieldAgeFilter = (val, settings?: string) => {
  if (!settings) return true

  const parsedSettings = JSON.parse(settings)
  if (parsedSettings?.dateRangeLimit?.hasOwnProperty('ageLimit') && parsedSettings?.dateRangeLimit?.ageLimit) {
    const olderThan = typeof parsedSettings?.dateRangeLimit?.ageLimit === 'string' ? parseInt(parsedSettings?.dateRangeLimit?.ageLimit) : parsedSettings?.dateRangeLimit?.ageLimit
    const filterStartDate = sub(new Date(), { years: olderThan })

    return parseISO(val) <= filterStartDate
  }
  return true
}